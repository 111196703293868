import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import School from '../models/School';

import PhaseFlatGrid from './PhaseFlatGrid';
import { Box, Typography, Chip, Grid, List, ListItem, ListItemDecorator, Link } from '@mui/joy';
import { Tabs, TabList, Tab, TabPanel, tabClasses } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faChair, faChild, faCircleInfo } from '@fortawesome/free-solid-svg-icons';


const SchoolCard: React.FC<{school: School}> = ({ school }) => {
	const isBigScreen = useMediaQuery({ query: '(min-width: 1024px)' });
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
	const [yearOutdated, setYearOutdated] = React.useState<number>(2018);

	return <Box display='flex' flexDirection='column' gap={1} overflow='scroll' sx={{ '::-webkit-scrollbar': { 'width': '0px', 'background': 'transparent' } }}>
		<Box display='flex' flexDirection='row'>
			<Box><img alt='' src={school.crestPath} height={48} /></Box>
			<Box display='flex' flexDirection='column' ml={2} gap={0.5}>
				<Typography fontSize='lg' fontWeight='bold'>{school.name}</Typography>
				<Box display='flex' flexDirection={{ xs: 'column', sm: 'row' }} gap={{ xs: 1, sm: 2 }}>
					<Typography fontSize='sm' textColor='#999'>{school.address}, {school.postalCode}</Typography>
					<Box display='flex' flexDirection='row' gap={1}>
						{[
							school.gender !== 'Mixed' ? school.gender : undefined,
							school.gep ? 'GEP' : undefined,
							school.sap ? 'SAP' : undefined,	
						].map(e=>e && <Chip key={e} variant='soft' size='sm' sx={{ color: '#999' }}>{e}</Chip>)}
					</Box>
				</Box>
			</Box>
		</Box>
		<Tabs sx={{ mt: 2, bgcolor: 'transparent' }}>
			<TabList size='sm' sx={{ [`&& .${tabClasses.root}`]: {
					flex: 'initial',
					bgcolor: 'transparent',
					fontWeight: 'bold',
					minWidth: '25%',
					'&:hover': { bgcolor: 'transparent' },
					[`&.${tabClasses.selected}`]: { color: 'primary.plainColor' },
				}
			}}>
				{/*<Tab>Profile</Tab>*/}
				<Tab>2B2C</Tab>
				<Tab>Subjects</Tab>
				<Tab>CCAs</Tab>
			</TabList>
			<TabPanel value={0}>
				<List size='sm' sx={{
					'.MuiListItem-root': { alignItems: 'baseline' },
					'--ListItemDecorator-size': '40px',
					'--List-gap': isTabletOrMobile && !isBigScreen ? '0px' : '-8px',
				}}>
					<ListItem>
						<ListItemDecorator><FA icon={faChair} /></ListItemDecorator>
						Vacancies.
					</ListItem>
					<ListItem>
						<ListItemDecorator><FA icon={faChild} /></ListItemDecorator>
						Appliants.
					</ListItem>
					<ListItem>
						<ListItemDecorator><Typography fontSize='xs' fontWeight='bold'>⁇</Typography></ListItemDecorator>
						MOE has not provided details about vacancies or applicants for balloting, only overall data is available.
					</ListItem>
					<ListItem>
						<ListItemDecorator><Typography fontSize='xs' fontWeight='bold'>100%</Typography></ListItemDecorator>
						MOE has not provided details, but all eligible applicants were offered a place.
					</ListItem>
					<ListItem>
						<ListItemDecorator><Typography fontSize='xs' fontWeight='bold'>0%</Typography></ListItemDecorator>
						MOE has not provided details about applicants, but no vacancies are available.
					</ListItem>
					<ListItem>
						<ListItemDecorator><FA icon={faCircleInfo} /></ListItemDecorator>
						<Typography fontSize='sm'>The citizen and residing details are only available after 2019.{' '}
						<Link sx={{ fontWeight: 'bold' }} onClick={()=>setYearOutdated(yearOutdated === 2018 ? 2008 : 2018)}>
							{yearOutdated === 2018 ? 'Show' : 'Hide'} data of 2009 - 2018
						</Link></Typography>
					</ListItem>
				</List>
				<Typography fontSize='sm' fontWeight='bold' mt={3} mb={1}>Phase 2B</Typography>
				<PhaseFlatGrid school={school} phaseCode='2B' yearOutdated={yearOutdated} />
				<Typography fontSize='sm' fontWeight='bold' mt={4} mb={1}>Phase 2C</Typography>
				<PhaseFlatGrid school={school} phaseCode='2C' yearOutdated={yearOutdated} />
			</TabPanel>
			<TabPanel value={1}>
				<Grid container>
					<Grid xs={12} sm={6}>
						{school.subjects.map((e: string, i: number)=> i < (school.subjects.length / 2) && <Typography key={i} fontSize='xs'>∙ {e}</Typography>)}
					</Grid>
					<Grid xs={12} sm={6}>
						{school.subjects.map((e: string, i: number)=> i >= (school.subjects.length / 2) && <Typography key={i} fontSize='xs'>∙ {e}</Typography>)}
					</Grid>
				</Grid>
			</TabPanel>
			<TabPanel value={2}>
				<Grid container spacing={2}>
				{Array.from(school.ccas.keys()).map((e: string, i: number)=>{
					return <Grid key={i} xs={12} sm={4}>
						<Typography fontSize='sm' fontWeight='bold' mb={0.5}>{e}</Typography>
						{school.ccas.get(e)!.map((e: string, i: number)=><Typography key={i} fontSize='xs'>∙ {e}</Typography>)}
					</Grid>
				})}
				</Grid>
			</TabPanel>
		</Tabs>
	</Box>;
}

export default SchoolCard;


import * as React from 'react';
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom';

import { P2b2cLogo, Colors, Dimens } from '../Resources';
import { Box, BoxProps, Typography, Link, Divider, Chip, Badge } from '@mui/joy';


const Footer: React.FC<BoxProps> = ({ ...props }) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const nav = useNavigate();
    return <Box width='100%' bgcolor={Colors.muiLightGray} sx={props?.sx}>
        <Box mx='auto' width='100%' maxWidth={Dimens.maxWidth}>
            <Box display='flex' flexDirection='column' my={2} px={2} gap={2} alignItems='start'>
                <P2b2cLogo size='20' color='#666' />
                <Divider />
                <Box display='flex' flexWrap='wrap' gap={3} sx={{ width: '100%' }}>
                    <Typography fontSize='xs' textColor='#999'>{`Copyright © 2024 Omnimind ${isTabletOrMobile ? '' : 'Pte. Ltd. All rights reserved.'}`}</Typography>
                    <Box display='flex' flex={1} justifyContent='flex-end'><img alt='' src='/images/omnimind_grey_icon.svg' width={100} /></Box>
                </Box>
            </Box>
        </Box>
    </Box>;
}

export default Footer;
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import School from '../models/School';
import { supportYears } from '../models/Registration';

import { Card, CardProps, Box, Divider, Typography, Button, Link } from '@mui/joy';
import { Select, Option } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faCircleRight } from '@fortawesome/free-solid-svg-icons';


export const supportPhases = [
	{ value: '2b', label: '2B' },
	{ value: '2c', label: '2C' },
	{ value: '2cs', label: '2CS' },
];

export const supportGender = [
	{ value: 'all', label: 'All' },
	{ value: 'mixed', label: 'Mixed' },
	{ value: 'girl', label: 'Girl' },
	{ value: 'boy', label: 'Boy' },
];

export const supportArea = [{ value: 'all', label: 'All' }].concat(School.allActiveArea().sort().map((e: string)=>Object.create({ value: e.toLowerCase(), label: e })));

export const supportCitizen = [
	// { value: -1, label: 'All' },
	{ value: 2, label: 'Singapore Citizen' },
	{ value: 1, label: 'Permanent Resident' },
];

export const supportDistance = [
	{ value: -1, label: 'All' },
	{ value: 3, label: 'within 1km' },
	{ value: 2, label: 'between 1-2km' },
	{ value: 1, label: 'outside 2km' },
];

export type RankFilter = {
	fromYear: number,
	toYear: number,
	phase: string,
	gender: string,
	area: string,
	citizen: number,
	distance: number,
}

const rankFilterToSearchParams = (filter: RankFilter) => {
	const sp: URLSearchParams = new URLSearchParams();
	sp.set('from', Math.min(filter.fromYear, filter.toYear).toString());
	sp.set('to', Math.max(filter.fromYear, filter.toYear).toString());
	sp.set('phas', filter.phase);
	sp.set('gend', filter.gender);
	sp.set('area', filter.area);
	sp.set('citi', filter.citizen.toString());
	sp.set('dist', filter.distance.toString());
	return sp;
}

const searchParamsToRankFilter = (sp: URLSearchParams) => {
	let aYear = parseInt(sp.get('from') ?? '');
	let maxYear = supportYears[supportYears.length - 1];
	aYear = supportYears.includes(aYear) ? aYear : maxYear;
	let bYear = parseInt(sp.get('to') ?? '');
	bYear = supportYears.includes(bYear) ? bYear : maxYear;
	let phase = sp.get('phas')?.toLowerCase() ?? '';
	phase = supportPhases.map(e => e.value).includes(phase) ? phase : supportPhases[0].value;
	let gender = sp.get('gend')?.toLowerCase() ?? '';
	gender = supportGender.map(e => e.value).includes(gender) ? gender : supportGender[0].value;
	let area = sp.get('area')?.toLowerCase() ?? '';
	area = supportArea.map(e => e.value).includes(area) ? area : supportArea[0].value;
	let citizen = parseInt(sp.get('citi') ?? '');
	citizen = supportCitizen.map(e => e.value).includes(citizen) ? citizen : supportCitizen[0].value;
	let distance = parseInt(sp.get('dist') ?? '');
	distance = supportDistance.map(e => e.value).includes(distance) ? distance : supportDistance[0].value;
	return {
		fromYear: Math.min(aYear, bYear),
		toYear: Math.max(aYear, bYear),
		phase: phase,
		gender: gender,
		area: area,
		citizen: citizen,
		distance: distance,
	} as RankFilter;
}

interface RankToolbarProps extends CardProps {
	loading?: boolean;
	onFiltered?: (filter: RankFilter) => void;
}

const RankToolbar: React.FC<RankToolbarProps> = ({ loading = false, onFiltered, ...props }) => {
	const [reset, setReset] = React.useState(0);
	const [searchParams, setSearchParams] = useSearchParams();
	const filter = React.useMemo(()=>searchParamsToRankFilter(searchParams), [searchParams]);
	React.useEffect(() => onFiltered && onFiltered(filter), [filter, onFiltered]);
	const nav = useNavigate();

	return <Card component='form' sx={props.sx} onSubmit={(event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const fi = searchParamsToRankFilter(data as URLSearchParams);
		const sp = rankFilterToSearchParams(fi);
		setSearchParams(sp);
	}}>
		<Box key={reset} display='flex' flexDirection='row' flexWrap='wrap' alignItems='center' gap={2}>
			<Typography fontSize='sm'>Phase</Typography>
			<Select defaultValue={filter.phase} disabled={loading} name='phas' size='sm' sx={{ width: 80 }}>
				{supportPhases.map(e=><Option key={e.value} value={e.value}>{e.label}</Option>)}
			</Select>
			<Divider orientation='vertical' inset='none' />
			<Typography fontSize='sm'>From year</Typography>
			<Select defaultValue={filter.fromYear} disabled={loading} name='from' size='sm' sx={{ width: 80 }}>
				{Array.from(supportYears).reverse().map(e=><Option key={e} value={e}>{e}</Option>)}
			</Select>
			<Typography fontSize='sm'>To</Typography>
			<Select defaultValue={filter.toYear} disabled={loading} name='to' size='sm' sx={{ width: 80 }}>
				{Array.from(supportYears).reverse().map(e=><Option key={e} value={e}>{e}</Option>)}
			</Select>
			<Divider orientation='vertical' inset='none' />
			<Typography fontSize='sm'>Gender</Typography>
			<Select defaultValue={filter.gender} disabled={loading} name='gend' size='sm' sx={{ width: 88 }}>
				{supportGender.map(e=><Option key={e.value} value={e.value}>{e.label}</Option>)}
			</Select>
			<Typography fontSize='sm'>Area</Typography>
			<Select defaultValue={filter.area} disabled={loading} name='area' size='sm' sx={{ width: 150 }}>
				{supportArea.map(e=><Option key={e.value} value={e.value}>{e.label}</Option>)}
			</Select>
			<Divider orientation='vertical' inset='none' />
			<Typography fontSize='sm'>Children of</Typography>
			<Select defaultValue={filter.citizen} disabled={loading} name='citi' size='sm' sx={{ width: 180 }}>
				{supportCitizen.map(e=><Option key={e.value} value={e.value}>{e.label}</Option>)}
			</Select>
			<Typography fontSize='sm'>Residing</Typography>
			<Select defaultValue={filter.distance} disabled={loading} name='dist' size='sm' sx={{ width: 150 }}>
				{supportDistance.map(e=><Option key={e.value} value={e.value}>{e.label}</Option>)}
			</Select>
			<Divider orientation='vertical' inset='none' />
			<Button size='sm' loading={loading} endDecorator={<FA icon={faCircleRight} />} type='submit'>Rank</Button>
		</Box>
		<Divider inset='none' sx={{ mt: 1 }}><Typography fontSize='xs' textColor='#999'>Popular Ranking</Typography></Divider>
		<Box display='flex' flexDirection='row' flexWrap='wrap' alignItems='center' gap={2}>
			<Link onClick={()=>{
				nav('?from=2024&to=2024&phas=2b');
				setReset(reset + 1);
			}}><Typography fontSize='sm' fontWeight='bold'>{'🔥 2024 2B Final All'}</Typography></Link>
			<Divider orientation='vertical' inset='none' />
			<Link onClick={()=>{
				nav('?from=2023&to=2023&phas=2b&gend=all&area=bukit+timah&citi=2&dist=3');
				setReset(reset + 1);
			}}><Typography fontSize='sm' fontWeight='bold'>{'🏡 2023 2B Bukit Timah SC < 1km'}</Typography></Link>
			<Divider orientation='vertical' inset='none' />
			<Link onClick={()=>{
				nav('?from=2020&to=2024&phas=2b');
				setReset(reset + 1);
			}}><Typography fontSize='sm' fontWeight='bold'>{'🖐🏼 2020-2024 2B Overall'}</Typography></Link>
		</Box>
	</Card>
}

export default RankToolbar;



export class Enroll {

	vacancy: number | undefined;
	applied: number | undefined;
	citizen: number | undefined;
	distance: number | undefined;
	rate: number;

	constructor(obj: any) {
		this.vacancy = obj.vacancy;
		this.applied = obj.applied;
		this.citizen = obj.citizen === 'SC' ? 2 : obj.citizen === 'PR' ? 1 : obj.citizen;
		this.distance = obj.distance === '<1' ? 3 : (obj.distance === '1-2' || obj.distance === '<2') ? 2 : obj.distance === '>2' ? 1 : obj.distance;
		this.rate = ((this.applied ?? 0) === 0) ? ((this.vacancy ?? 0) > 0 ? 1 : 0) : Math.min((this.vacancy ?? 0) / this.applied!, 1);
	}

}

export class Phase {

	code: string;
	total: Enroll;
	ballot: Enroll | undefined;

	constructor(obj: any) {
		this.code = obj.code;
		this.total = new Enroll(obj.total);
		this.ballot = obj.ballot ? new Enroll(obj.ballot) : undefined;
	}

	eligible(citizen: number, distance: number) {
		let vacancy;
		let applied;
		let eligCiti;
		let eligDist;

		if (citizen > 0) {
			if (this.ballot?.citizen) {
				eligCiti = citizen >= this.ballot.citizen;
			} else if (this.total.citizen) {
				eligCiti = citizen >= this.total.citizen;
			}
		}

		if (distance > 0) {
			if (this.ballot?.distance) {
				eligDist = distance >= this.ballot.distance;
			} else if (this.total.distance) {
				eligDist = distance >= this.total.distance;
			}     
		}

		if (eligCiti === false || eligDist === false) {
			vacancy = 0;
			applied = this.total.applied;
		} else if (eligCiti === true && eligDist === true) {
			if ((this.ballot?.citizen && citizen > this.ballot.citizen) || (this.ballot?.distance && distance > this.ballot.distance)) {
				vacancy = this.total.vacancy! - (this.ballot.vacancy ?? 0);
				applied = vacancy;
			} else if ((this.total.citizen && citizen > this.total.citizen) || (this.total.distance && distance > this.total.distance)) {
				vacancy = this.total.vacancy;
				applied = vacancy;
			} else if (this.ballot) {
				vacancy = this.ballot.vacancy ?? this.total.vacancy;
				applied = this.ballot.applied ?? this.total.applied;
			} else {
				vacancy = this.total.vacancy;
				applied = vacancy;
			}
		} else if (eligCiti === true && this.total.distance === undefined && this.ballot === undefined) {
			vacancy = this.total.vacancy;
			applied = vacancy;
		} else if (eligDist === true && this.total.citizen === undefined && this.ballot === undefined) {
			vacancy = this.total.vacancy;
			applied = vacancy;
		} else {
			vacancy = this.total.vacancy;
			applied = this.total.applied;
		}

		return new Enroll({
			vacancy: vacancy,
			applied: applied,
			citizen: citizen,
			distance: distance,
		});
	}

}

export class PhaseTrend {

	phases: Array<Phase>;
	seq: Array<Enroll>;
	sum: Enroll;

	constructor(phases: Array<Phase>, citizen: number, distance: number) {
		this.phases = phases;
		this.seq = phases.map(e => e.eligible(citizen, distance));
		this.sum = new Enroll({
			vacancy: this.seq.reduce((sum, e) => sum + (e.vacancy ?? 0), 0),
			applied: this.seq.reduce((sum, e) => sum + (e.applied ?? 0), 0),
			citizen: citizen,
			distance: distance,
		});
	}

	static from(schoolCode: string, phaseCode: string, fromYear: number, toYear: number, citizen: number, distance: number) {
		let years = new Array<Array<Registration>>();
		for(let i = Math.min(fromYear, toYear); i <= Math.max(fromYear, toYear); i++) {
			years.push(Registration.allForYear(i));
		}
		let phases = years.map((regs: Array<Registration>)=>{
			let reg = regs.find((reg: Registration)=>reg.schoolCode === schoolCode);
			let phase = reg?.phase(phaseCode) ?? new Phase({ code: phaseCode, total: {} });
			return phase;
		});
		return new PhaseTrend(phases, citizen, distance);
	}

}

const minYear = 2009;
const maxYear = 2024;
export const supportYears = new Array<number>(maxYear - minYear + 1).fill(minYear).map((e, i) => e+i);

export class Registration {

	schoolName: string;
	schoolCode: string;
	year: number;
	vacancy: number;
	phases: Array<Phase>;

	constructor(obj: any) {
		this.schoolName = obj.schoolName;
		this.schoolCode = obj.schoolCode;
		this.year = obj.year;
		this.vacancy = obj.vacancy;
		this.phases = obj.phases.map((e: any)=>new Phase(e));
	}

	phase(code: string) {
		return this.phases.find((e: Phase) => e.code.toLowerCase() === code.toLowerCase());
	}

	private static _cacheByYear = new Map<string, Array<Registration>>();

	static allForYear(year: number) {
		let key = year.toString();
		if (Registration._cacheByYear.get(key) !== undefined) {
			return Registration._cacheByYear.get(key);
		}
		let request = new XMLHttpRequest();
		request.open('GET', `/data/p1r/p1r_${year}.json`, false);
		request.send(null);
		let json = JSON.parse(request.responseText);
		let regs = json.map((e: any) => new Registration(e));
		Registration._cacheByYear.set(key, regs);
		return regs;
	}

	static test() {
		let request = new XMLHttpRequest();
		request.open('GET', `/data/p1r/p1r_test.json`, false);
		request.send(null);
		let json = JSON.parse(request.responseText);
		return json.map((e: any) => new Registration(e));
	}

}








export default class Place {
	
	address: string;
	postal: string;
	blk: string;
	road: string;
	building: string;

	constructor(obj: any) {
		this.address = obj.ADDRESS;
		this.postal = obj.POSTAL;
		this.blk = obj.BLK_NO;
		this.road = obj.ROAD_NAME;
		this.building = obj.BUILDING;
	}

	toJSON() {
		return {
			ADDRESS: this.address,
			POSTAL: this.postal,
			BLK_NO: this.blk,
			ROAD_NAME: this.road,
			BUILDING: this.building,
		};
	}

	private static _searching: string;

	static search(val: string, onCompleted: (places: Array<Place>)=>void) {
		Place._searching = val;
		let request = new XMLHttpRequest();
		request.open('GET', `https://www.onemap.gov.sg/api/common/elastic/search?searchVal=${encodeURIComponent(val)}&returnGeom=N&getAddrDetails=Y&pageNum=1`, true);
		request.onload = (event) => {
			if (Place._searching !== val) return;
			let json = JSON.parse(request.responseText);
			let places = json.results.map((e: any)=>new Place(e));
			onCompleted(places);
		};
		request.send(null);
	}

}
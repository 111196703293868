import * as React from 'react';
import { useParams } from 'react-router-dom';

import School from '../models/School';
import { supportYears } from '../models/Registration';
import { Dimens } from '../Resources';

import SEO from '../utils/SEO';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import SchoolCard from '../components/SchoolCard';
import { Box, Typography, CircularProgress } from '@mui/joy';


const SchoolPage: React.FC = () => {
	const { code } = useParams();
	const [loading, setLoading] = React.useState<boolean>(true);
	const [school, setSchool] = React.useState<School | undefined>();

	React.useEffect(()=>{
		setLoading(true);
		let school = School.allActive().find((school: School)=>school.code === code);
		setSchool(school);
		setLoading(false);
	}, [code]);

	return <Box display='flex' flexDirection='column' flex={1} alignItems='center'>
		<SEO
			title={school?.name ?? ''}
			desc={`Comprehensive data on vacancies, applicants, and balloting results for ${school?.name}'s Phase 2B, Phase 2C, and Phase 2CS. Explore detailed information on subjects and co-curricular activities (CCAs) offered by the school. Historical data available from 2009 to 2024. Detailed data for 2019, 2020, 2021, 2022, 2023, and 2024.`}
			canonicalUrl={`www.property2b2c.com/school/${school?.code}`}
		/>
		<NavBar />
		<Box display='flex' flexDirection='column' flex={1} mx={3} mt={8} width='90%' maxWidth={Dimens.maxFocusWidth}>
		{loading
			? <CircularProgress sx={{ my: 4, alignSelf: 'center' }} />
			: school
				? <SchoolCard school={school} />
				: <Typography fontSize='md' fontWeight='bold' textAlign='center'><Typography fontSize={48}>🏫</Typography><br/>School not found!</Typography>}
		</Box>
		<Footer sx={{ mt: 6 }} />
	</Box>;
}

export default SchoolPage;



export default class School {

	private static girls = [
	    { name: "CHIJ (Katong) Primary", code: "5637" },
	    { name: "CHIJ (Kellock)", code: "5005" },
	    { name: "CHIJ Our Lady of Good Counsel", code: "5017" },
	    { name: "CHIJ Our Lady of the Nativity", code: "5007" },
	    { name: "CHIJ Our Lady Queen of Peace", code: "5019" },
	    { name: "CHIJ Primary (Toa Payoh)", code: "5004" },
	    { name: "CHIJ St. Nicholas Girls' School (Primary Section)", code: "7118" },
	    { name: "Haig Girls' School", code: "1038" },
	    { name: "Marymount Convent School", code: "7301" },
	    { name: "Methodist Girls' School (Primary)", code: "5027" },
	    { name: "Paya Lebar Methodist Girls' School (Primary)", code: "5635" },
	    { name: "Raffles Girls' Primary School", code: "1073" },
	    { name: "Singapore Chinese Girls' Primary School", code: "5026" },
	    { name: "St. Anthony's Canossian Primary School", code: "5011" },
	    { name: "St. Margaret's School (Primary)", code: "5013" },
	];

	private static boys = [
		{ name: "Anglo-Chinese School (Junior)", code: "5001" },
		{ name: "Anglo-Chinese School (Primary)", code: "5638" },
		{ name: "Catholic High School (Primary Section)", code: "7102" },
		{ name: "Maris Stella High School (Primary Section)", code: "7111" },
		{ name: "Montfort Junior School", code: "5018" },
		{ name: "St. Andrew's Junior School", code: "5009" },
		{ name: "St. Gabriel's Primary School", code: "5012" },
		{ name: "St. Joseph's Institution Junior", code: "5014" },
		{ name: "St. Stephen's School", code: "5015" },
	];

	private static merged = [
		{
			id: 'eunos-primary-school',
			school_codes_o_level_s: '1119',
			name_s: 'Eunos Primary School',
			school_area_s: 'Geylang',
			address_s: '95 Jalan Eunos',
			postal_code_s: '419529',
			school_status_ss: [],
		},
		{
			id: 'guangyang-primary-school',
			school_codes_o_level_s: '1735',
			name_s: 'Guangyang Primary School',
			school_area_s: 'Bishan',
			address_s: '6 Bishan Street 12',
			postal_code_s: '579806',
			school_status_ss: [],
		},
		{
			id: 'juying-primary-school',
			school_codes_o_level_s: '1726',
			name_s: 'Juying Primary School',
			school_area_s: 'Jurong West',
			address_s: '31 Jurong West Street 91',
			postal_code_s: '649037',
			school_status_ss: [],
		},
		{
			id: 'stamford-primary-school',
			school_codes_o_level_s: '1174',
			name_s: 'Stamford Primary School',
			school_area_s: 'Central',
			address_s: '1 Victoria Lane',
			postal_code_s: '198423',
			school_status_ss: [],
		},
	];


	id: string;
	code: string;
	name: string;
	area: string;
	address: string;
	postalCode: string;
	gep: boolean;
	sap: boolean;
	gender: string;
	shortName: string;
	crestPath: string;
	subjects: Array<string>;
	ccas: Map<string, Array<string>>;

	constructor(obj: any) {
		this.id = obj.id;
		this.code = obj.school_codes_o_level_s;
		this.name = obj.name_s;
		this.area = obj.school_area_s;
		this.address = obj.address_s;
		this.postalCode = obj.postal_code_s;
		this.gep = obj.school_status_ss.includes('Gifted Education Programme');
		this.sap = obj.school_status_ss.includes('Specialised Assistance Plan (SAP)');
		this.gender = School.girls.find((e: any)=>e.code === this.code) ? 'Girl' : School.boys.find((e: any)=>e.code === this.code) ? 'Boy' : 'Mixed';
		this.shortName = this.name.replaceAll(/( Primary| \(Primary\)| \(Primary Section\)| School)/g, '');
		this.crestPath = `/school-logos/${this.name.toLowerCase().replaceAll(' ', '-').replaceAll(/['().]/g, '').replaceAll('-primary-section', '')}.jpg`;
		this.subjects = Array.from(new Set(obj.subjects_offered_ss));
		this.ccas = new Map();
		if (obj.co_cirricular_activities_full_path) {
			let ccasPlain = obj.co_cirricular_activities_full_path.map((e: string)=>e.split('|'));
			ccasPlain.forEach((e: Array<string>)=>{
				let key = e[0];
				if (this.ccas.get(key) === undefined) {
					this.ccas.set(key, new Array<string>());
				}
				this.ccas.get(key)!.push(e[1]);
			});
		}
	}

	private static _allActive: Array<School>;
	private static _allMerged: Array<School>;
	private static _allActiveArea: Array<string>;

	static allActiveArea() {
		if (School._allActiveArea) return School._allActiveArea;
		const areaSet = new Set(School.allActive().map((e: School)=>e.area));
		School._allActiveArea = Array.from(areaSet);
		return School._allActiveArea;
	}

	static allActive() {
		if (School._allActive) return School._allActive;
		let request = new XMLHttpRequest();
		request.open('GET', '/data/moe_school.json', false);
		request.send(null);
		let json = JSON.parse(request.responseText);
		School._allActive = json.map((e: any) => new School(e));
		return School._allActive;
	}

	static allMerged() {
		if (School._allMerged) return School._allMerged;
		School._allMerged = School.merged.map((e: any) => new School(e));
		return School._allMerged;
	}

	static all() {
		return School.allActive().concat(School.allMerged());
	}

	static allOnPostal(code: string, blk: string, onCompleted: (obj: any)=>void) {
		let request = new XMLHttpRequest();
		// request.open('GET', `http://localhost:8080/s_api/ominear?dist=500&postalcode=${code}&blkno=${blk}`, true);
		request.open('GET', `https://www.property2b2c.com/api/ominear?dist=500&postalcode=${code}&blkno=${blk}`, true);
		request.onload = (event) => {
			let json = JSON.parse(request.responseText);
			onCompleted({
				distance1: json.SearchResults.filter((e: any)=>e.DIST_CODE === '1').map((e: any)=>School.find(e.SCHOOLNAME)),
				distance2: json.SearchResults.filter((e: any)=>e.DIST_CODE === '2').map((e: any)=>School.find(e.SCHOOLNAME)),
			});
		};
		request.send(null);
	}

	static find(name: string) {
		let nameSegs = name.trim().replaceAll(/[()]/g, ' ').toLowerCase().split(' ');
		let schools = School.all().filter((sch: School)=>{
			let lowerCaseName = sch.name.toLowerCase();
			return nameSegs.every((seg: string)=>lowerCaseName.includes(seg));
		});
		if (schools.length === 0) return undefined;
		schools.sort((a: School, b: School)=>{
			if (a.name.length < b.name.length) return -1;
			if (a.name.length > b.name.length) return 1;
			return 0;
		});
		return schools[0];
	}

}
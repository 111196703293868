import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import Rank from '../models/Rank';
import School from '../models/School';
import { Styles, Dimens } from '../Resources';

import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import SchoolCard from '../components/SchoolCard';
import PhaseTrendChart from '../components/PhaseTrendChart';
import RankToolbar, { RankFilter } from '../components/RankToolbar';
import { Box, Typography, Grid, Chip, Card } from '@mui/joy';
import { Modal, ModalDialog, DialogTitle, ModalClose } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faChair, faChild } from '@fortawesome/free-solid-svg-icons';


const eligibleCitizenLabel = [
	{ value: -1, label: '' },
	{ value: 2, label: 'SC' },
	{ value: 1, label: 'PR' },
];

const eligibleDistanceLabel = [
	{ value: -1, label: '' },
	{ value: 3, label: '< 1km' },
	{ value: 2, label: '1-2km' },
	{ value: 1, label: '> 2km' },
];

const RankPage: React.FC = () => {
	const isBigScreen = useMediaQuery({ query: '(min-width: 1024px)' });
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
	const [filter, setFilter] = React.useState<RankFilter | undefined>();
	const [ranks, setRanks] = React.useState<Array<Rank> | undefined>();
	const [openSchool, setOpenSchool] = React.useState<School | undefined>();

	React.useEffect(()=>{
		if (!filter) return;
		let ranks = Rank.create(filter.fromYear, filter.toYear, filter.phase, filter.citizen, filter.distance).filter((r: Rank)=>
			(filter.gender === 'all' ? true : filter.gender === r.school.gender.toLowerCase())
			&& (filter.area === 'all' ? true : filter.area === r.school.area.toLowerCase()));
		setRanks(ranks);
	}, [filter]);

	return <Box display='flex' flexDirection='column' flex={1} alignItems='center'>
		<NavBar />
		<Box display='flex' flexDirection='column' flex={1} mx={3} maxWidth={Dimens.maxFocusWidth}>
			<RankToolbar onFiltered={(filter: RankFilter)=>setFilter(filter)} sx={{ my: 4 }} />
			{filter && ranks && <Box display='flex' flexDirection='column' gap={isTabletOrMobile ? 2 : 0}>
				{!isTabletOrMobile && <Grid container spacing={2} flexGrow={1} alignItems='center' p={0} m={0} sx={{ whiteSpace: 'nowrap' }}>
					<Grid xs={0.7}><Typography fontSize='xs' textColor='#999' fontWeight='normal'>No.</Typography></Grid>
					<Grid flex={1}><Typography fontSize='xs' textColor='#999' fontWeight='normal'>School</Typography></Grid>
					<Grid xs={1} textAlign='center'><Typography fontSize='xs' textColor='#999' fontWeight='normal'>GEP</Typography></Grid>
					<Grid xs={1} textAlign='center'><Typography fontSize='xs' textColor='#999' fontWeight='normal'>SAP</Typography></Grid>
					<Grid xs={1} textAlign='center'><Typography fontSize='xs' textColor='#999' fontWeight='normal'>Gender</Typography></Grid>
					<Grid xs={1.35} ml={1}><Typography fontSize='xs' textColor='#999' fontWeight='normal'>Area</Typography></Grid>
					<Grid xs='auto' mx={1}><Typography width={160} fontSize='xs' textColor='#999' fontWeight='normal'>Vacancies / Applied - Trend</Typography></Grid>
					<Grid xs={0.9}><Typography fontSize='xs' textColor='#999' fontWeight='normal'>V/A Rate</Typography></Grid>
				</Grid>}
				{ranks && ranks.map((r: Rank, i: number)=><RankItem key={r.no} rank={r} isMobile={isTabletOrMobile} onClick={(school: School)=>setOpenSchool(school)} />)}
			</Box>}
		</Box>
		<Footer sx={{ mt: 6 }} />
		<Modal open={openSchool !== undefined} onClose={()=>setOpenSchool(undefined)} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<ModalDialog sx={{
				...Dimens.maxDialogWidth,
				minWidth: isTabletOrMobile && !isBigScreen ? '100%' : undefined,
				minHeight: isTabletOrMobile && !isBigScreen ? '100%' : undefined,
				borderRadius: isTabletOrMobile && !isBigScreen ? '0px' : undefined,
				paddingTop: isTabletOrMobile && !isBigScreen ? '36px' : undefined,
			}}>
				<ModalClose />
				<SchoolCard school={openSchool!} />
			</ModalDialog>
		</Modal>
	</Box>;
}

interface RankItemProps {
	rank: Rank;
	isMobile: boolean;
	onClick: (school: School)=>void;
}

const RankItem: React.FC<RankItemProps> = ({ rank, isMobile, onClick }) => {
	let available = rank.trend.sum.applied! > 0;
	let eligible = rank.trend.sum.vacancy! > 0;
	let noVac;
	if (!eligible) {
		let citiLabel = eligibleCitizenLabel.find(e => e.value === rank.citizen)?.label;
		let distLabel = eligibleDistanceLabel.find(e => e.value === rank.distance)?.label;
		noVac = citiLabel || distLabel ? <>No Vac. for<br />{`${citiLabel} ${distLabel}`}</> : <>No Vac.</>;
	}
	let rate = rank.trend.sum.rate;
	let percent = rate === 1 ? '100' : Number(rate * 100).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
	return isMobile ?
	<Card onClick={()=>onClick(rank.school)} sx={{ gap: 0.5, cursor: 'pointer' }}>
		<Box display='flex' flexDirection='row'>
			<Box display='flex' flexDirection='column' flex={1} gap={0.5}>
				<Box display='flex' flexDirection='row'>
					<Typography fontSize='lg' fontWeight='bold' width={40}>{available ? rank.no : '-'}</Typography>
					<Typography fontSize='lg' fontWeight='bold' flex={1}>{rank.school.shortName}</Typography>
				</Box>
				<Box display='flex' flexDirection='row' gap={1} ml={5} alignItems='center'>
					<Typography fontSize='xs' textColor='#999'>{rank.school.area}</Typography>
					{[
						rank.school.gender !== 'Mixed' ? rank.school.gender : undefined,
						rank.school.gep ? 'GEP' : undefined,
						rank.school.sap ? 'SAP' : undefined,	
					].map(e=>e && <Chip key={e} variant='soft' size='sm' sx={{ color: '#999' }}>{e}</Chip>)}
				</Box>
			</Box>
			<Box display='flex' flexDirection='column' textAlign='right' gap={0.5} alignSelf='center'>
				{eligible ? (available
					? <Typography fontSize='lg' fontWeight='bold'>{percent}<Typography fontSize='xs' fontWeight='bold'>%</Typography></Typography>
					: <Typography fontSize='lg' fontWeight='bold'>-</Typography>)
				: <Typography fontSize='xs' textColor='#999'>{noVac}</Typography>}
				{eligible && <Typography fontSize='xs' textColor='#999'><FA icon={faChair} color='#bbb' /> {rank.trend.sum.vacancy} <FA icon={faChild} color='#bbb' /> {rank.trend.sum.applied}</Typography>}
			</Box>
		</Box>
		{rank.trend.seq.length > 1 && <Box height={36} alignSelf='end'><PhaseTrendChart trend={rank.trend} width={160} height={48} /></Box>}
	</Card> :
	<Grid onClick={()=>onClick(rank.school)} container spacing={2} flexGrow={1} alignItems='center' p={0} m={0} sx={{ whiteSpace: 'nowrap', borderTop: Styles.divider, cursor: 'pointer' }}>
		<Grid xs={0.7}><Typography fontSize='lg' fontWeight='bold'>{available ? rank.no : '-'}</Typography></Grid>
		<Grid flex={1}><Typography fontSize='lg' fontWeight='bold'>{rank.school.shortName}</Typography></Grid>
		<Grid xs={1} textAlign='center'>{rank.school.gep && <Typography fontSize='xs' textColor='#999'>GEP</Typography>}</Grid>
		<Grid xs={1} textAlign='center'>{rank.school.sap && <Typography fontSize='xs' textColor='#999'>SAP</Typography>}</Grid>
		<Grid xs={1} textAlign='center'>{rank.school.gender !== 'Mixed' && <Typography fontSize='xs' textColor='#999'>{rank.school.gender}</Typography>}</Grid>
		<Grid xs={1.35} ml={1} textAlign='left'><Typography fontSize='xs' textColor='#999'>{rank.school.area}</Typography></Grid>
		<Grid xs='auto' mx={1}><PhaseTrendChart trend={rank.trend} width={160} height={48} /></Grid>
		<Grid xs={0.9}>
			{eligible ? (available
				? <Typography fontSize='lg' fontWeight='bold'>{percent}<Typography fontSize='xs' fontWeight='bold'>%</Typography></Typography>
				: <Typography fontSize='lg' fontWeight='bold'>-</Typography>)
			: <Typography fontSize='xs' textColor='#999'>{noVac}</Typography>}
			{eligible && <Typography fontSize='xs' textColor='#999'>{`${rank.trend.sum.vacancy} / ${rank.trend.sum.applied}`}</Typography>}
		</Grid>
	</Grid>;
}

export default RankPage;


import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import Place from '../models/Place';
import { supportYears } from '../models/Registration';
import { useSettings } from '../models/Settings';

import { Card, CardProps, Box, Divider, Typography, Button, Switch, Link, LinearProgress } from '@mui/joy';
import { Autocomplete, AutocompleteOption } from '@mui/joy';
import { Select, Option } from '@mui/joy';
import { Modal, ModalDialog, DialogTitle } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faRotateLeft, faThumbsUp } from '@fortawesome/free-solid-svg-icons';


const supportGender = [
	{ value: 'all', label: 'All' },
	{ value: 'mixed', label: 'Mixed' },
	{ value: 'girl', label: 'Girl' },
	{ value: 'boy', label: 'Boy' },
];

const supportCitizen = [
	// { value: -1, label: 'All' },
	{ value: 2, label: 'Singapore Citizen' },
	{ value: 1, label: 'Permanent Resident' },
];

export type SolutionFilter = {
	postal: string,
	blk: string,
	gender: string,
	citizen: number,
}

interface SolutionToolbarProps extends CardProps {
	loading?: boolean;
	onFiltered?: (filter: SolutionFilter) => void;
}

const SolutionToolbar: React.FC<SolutionToolbarProps> = ({ loading = false, onFiltered, ...props }) => {
	const settings = useSettings();
	const [openDataSet, setOpenDataSet] = React.useState<boolean>(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const filter = React.useMemo(()=>{
		let gender = searchParams.get('gend')?.toLowerCase() ?? '';
		gender = supportGender.map(e => e.value).includes(gender) ? gender : supportGender[0].value;
		let citizen = parseInt(searchParams.get('citi') ?? '');
		citizen = supportCitizen.map(e => e.value).includes(citizen) ? citizen : supportCitizen[0].value;
		return {
			postal: searchParams.get('post'),
			blk: searchParams.get('blk'),
			gender: gender,
			citizen: citizen,
		} as SolutionFilter;
	}, [searchParams]);

	const [searchLoading, setSearchLoading] = React.useState<boolean>(true);
	const [searchTimer, setSearchTimer] = React.useState<any>();
	const [searchValue, setSearchValue] = React.useState<string>('');
	const [searchPlaces, setSearchPlaces] = React.useState<Array<Place>>(settings.recentPlaces);
	const [place, setPlace] = React.useState<Place | null>(null);
	const [gender, setGender] = React.useState<string>(filter.gender);
	const [citizen, setCitizen] = React.useState<number>(filter.citizen);
	
	React.useEffect(()=>{
		if (place) {
			searchParams.set('post', place.postal);
			searchParams.set('blk', place.blk);
			searchParams.set('addr', place.address);
		}
		searchParams.set('gend', gender);
		searchParams.set('citi', citizen.toString());
		setSearchParams(searchParams);
	}, [place, gender, citizen, searchParams, setSearchParams]);

	React.useEffect(()=>onFiltered && onFiltered(filter), [filter, onFiltered]);

	return <Card sx={props.sx} >
		<Box display='flex' flexDirection='row' flexWrap='wrap' alignItems='center' gap={2}>
			<Autocomplete required disabled={loading} size='sm' startDecorator={<FA icon={faMagnifyingGlass} />} sx={{ minWidth: 320 }}
				loading={searchLoading} loadingText={<LinearProgress size='sm' sx={{ mx: 2 }} />}
				noOptionsText='No such places.'
				placeholder = 'Enter postal code or addresss...'
				options={searchPlaces}
				getOptionLabel={option => option.address}
				value={place}
				onChange={(event, value)=>{
					setPlace(value);
					if (value) settings.addRecentPlace(value);
				}}
				inputValue={searchValue}
				onInputChange={(event, value, reason)=>{
					setSearchLoading(true);
					if (searchTimer) {
						window.clearTimeout(searchTimer);
					}
					if (reason === 'clear') {
						setSearchValue(value);	
						setSearchPlaces(settings.recentPlaces);
						setPlace(null);
						return;
					}
					if (reason === 'reset') {
						if (value === '') return;
						setSearchValue(value);
						return;
					}
					setSearchValue(value);
					setSearchPlaces(value === '' ? settings.recentPlaces : []);
					setPlace(null);
					if (value.length < 3) return;
					let timer = setTimeout(()=>{
						Place.search(value, (places: Array<Place>)=>{
							setSearchLoading(false);
							setSearchPlaces(places.filter((place: Place)=>place.postal && place.blk));
						});
					}, 500);
					setSearchTimer(timer);
				}}
				renderOption={(props, option, { selected })=>{
					delete (props as any).key;
					return <AutocompleteOption key={option.address} {...props}>
						<Typography fontSize='sm' startDecorator={<FA icon={searchValue.length === 0 ?  faRotateLeft : faMagnifyingGlass} fontSize={12} color='#bbb' />} gap={1} alignItems='baseline'>
							{option.address}
						</Typography>
					</AutocompleteOption>
				}}
			/>
			<Divider orientation='vertical' inset='none' />
			<Typography fontSize='sm'>Children of</Typography>
			<Select disabled={loading} size='sm' sx={{ width: 180 }} value={citizen} onChange={(event, value)=>{setCitizen(value!)}}>
				{supportCitizen.map(e=><Option key={e.value} value={e.value}>{e.label}</Option>)}
			</Select>
			<Divider orientation='vertical' inset='none' />
			<Typography fontSize='sm'>Gender</Typography>
			<Select disabled={loading} size='sm' sx={{ width: 88 }} value={gender} onChange={(event, value)=>{setGender(value!)}}>
				{supportGender.map(e=><Option key={e.value} value={e.value}>{e.label}</Option>)}
			</Select>
		</Box>
		<Divider inset='none' sx={{ my: 1 }} />
		<Box display='flex' flexDirection='row' flexWrap='wrap' alignItems='center' gap={2}>
			<Typography fontSize='sm'>
				Solution based on data
				<Link disabled={loading} fontWeight='bold' ml={0.75} onClick={()=>setOpenDataSet(true)}>
					{`from ${settings.solutionDataSet.fromYear} to ${settings.solutionDataSet.toYear}`}
				</Link>
			</Typography>
			<Divider orientation='vertical' inset='none' />
			<Switch checked={settings.hideBadSolution} disabled={loading} onChange={(event)=>settings.hideBadSolution = event.target.checked}
				startDecorator={<Typography fontSize='sm' startDecorator={<FA icon={faThumbsUp} />}>Recommend</Typography>} />
		</Box>
		<Modal open={openDataSet} onClose={()=>setOpenDataSet(false)}>
			<ModalDialog>
				<DialogTitle>Solution Data Set</DialogTitle>
				<form onSubmit={(event) => {
					event.preventDefault();
					let data = new FormData(event.currentTarget);
					let a = parseInt(data.get('from') as string);
					let b = parseInt(data.get('to') as string);
					settings.solutionDataSet = {
						fromYear: Math.min(a, b),
						toYear: Math.max(a, b),
					};
					setOpenDataSet(false);
				}}>
					<Box display='flex' flexDirection='row' flexWrap='wrap' alignItems='center' gap={2}>
						<Typography fontSize='sm'>Solution based on rankings data from</Typography>
						<Select defaultValue={settings.solutionDataSet.fromYear} disabled={loading} name='from' size='sm' sx={{ width: 80 }}>
							{Array.from(supportYears).reverse().map(e=><Option key={e} value={e}>{e}</Option>)}
						</Select>
						<Typography fontSize='sm'>to</Typography>
						<Select defaultValue={settings.solutionDataSet.toYear} disabled={loading} name='to' size='sm' sx={{ width: 80 }}>
							{Array.from(supportYears).reverse().map(e=><Option key={e} value={e}>{e}</Option>)}
						</Select>
					</Box>
					<Typography fontSize='xs' textColor='#999' my={2}>
						In 2022, MOE doubled the slots for children applying for the Phase 2C from 20 to 40 students per primary school. Therefore, started from 2022 is recommended.
					</Typography>
					<Divider inset='none' sx={{ mt: 2, mb: 2 }} />
					<Box display='flex' flexDirection='row' gap={2}>
						<Button variant='outlined' color='neutral' sx={{ flex: 1 }} onClick={()=>setOpenDataSet(false)}>Cancel</Button>
						<Button type='submit' sx={{ flex: 1 }}>Apply</Button>
					</Box>
				</form>
			</ModalDialog>
		</Modal>
	</Card>
}

export default SolutionToolbar;



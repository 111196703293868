import * as React from 'react';

import { Dimens } from '../Resources';

import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import { Box, Typography } from '@mui/joy';


const HomePage: React.FC = () => {
	return <Box display='flex' flexDirection='column' flex={1} alignItems='center'>
		<NavBar />
		<Box display='flex' flexDirection='column' alignItems='center' flex={1} mt={3} width='100%' sx={{
			'backgroundColor': '#000',
			'backgroundImage': 'linear-gradient(0deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.75)), url(/images/banner_0.jpg)',
			'backgroundRepeat': 'no-repeat',
			'backgroundPosition': 'bottom',
			'backgroundSize': 'cover',
		}}>
			<Box flex={1} />
			<Typography flex={10} px={3} fontSize={{ xs: 48, sm: 72 }} fontWeight='bold' textColor='white' textAlign='center'
				lineHeight={1.1} letterSpacing={{ xs: '-0.1rem', sm: '-0.15rem' }}>
				Better school<br />Better living
			</Typography>
		</Box>
		<Footer />
	</Box>;
}

export default HomePage;


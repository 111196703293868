import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

interface SEOProps {
    title: string;
    desc: string;
    canonicalUrl: string;
    children?: React.ReactNode;
}

const SEO : React.FC<SEOProps> = (props: SEOProps) => {
    return <HelmetProvider>
        <Helmet>
            <meta charSet='utf-8' />
            <title>{props.title}</title>
            <meta name='description' content={props.desc} />
            {props.canonicalUrl && <link rel='canonical' href={props.canonicalUrl} />}
            {props.children}
        </Helmet>
    </HelmetProvider>;
}

export default SEO;



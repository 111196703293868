import Cookie from 'js-cookie';
import { useState, useEffect } from 'react';
import Place from './Place';

type SolutionDataSet = {
	fromYear: number,
	toYear: number,
}

class Settings {

	private _hideBadSolution: boolean;
	private _solutionDataSet: SolutionDataSet;
	private _recentPlaces: Array<Place>;
	private _updateListeners: Array<()=>void>;

	get hideBadSolution() {
		return this._hideBadSolution;
	}

	set hideBadSolution(value: boolean) {
		if (this._hideBadSolution === value) return;
		this._hideBadSolution = value;
		this._updateListeners.forEach(e => e());
		this.save();
	}

	get solutionDataSet() {
		return this._solutionDataSet;
	}

	set solutionDataSet(value: SolutionDataSet) {
		if (this._solutionDataSet === value) return;
		this._solutionDataSet = value;
		this._updateListeners.forEach(e => e());
		this.save();
	}

	addRecentPlace(place: Place) {
		if (this._recentPlaces.find(e=>e.postal === place.postal && e.blk === place.blk)) return;
		if (this._recentPlaces.length === 10) this._recentPlaces.pop();
		this._recentPlaces.unshift(place);
		this._updateListeners.forEach(e => e());
		this.save();
	}

	get recentPlaces() {
		return this._recentPlaces;
	}

	addUpdateListener(listener: ()=>void) {
		this._updateListeners.push(listener);
	}

	removeUpdateListener(listener: ()=>void) {
		const index = this._updateListeners.indexOf(listener);
		if (index === -1) return;
		this._updateListeners.splice(index, 1);
	}

	constructor(obj: any) {
		this._hideBadSolution = obj._hideBadSolution ?? true;
		this._solutionDataSet = obj._solutionDataSet ?? { fromYear: 2022, toYear: 2024 };
		this._recentPlaces = obj._recentPlaces?.map((e: any)=>new Place(e)) ?? [];
		this._updateListeners = [];
	}

	static load = () => {
		let data = Cookie.get('settings');
		let obj = data ? JSON.parse(data) : {};
		let settings = new Settings(obj);
		return settings;
	}

	private save() {
		Cookie.set('settings', JSON.stringify(this));
	}

}

const instance = Settings.load();

export function useSettings() {
	const [update, setUpdate] = useState(0);
	useEffect(()=>{
		const listener = ()=>setUpdate(Date.now());
		instance.addUpdateListener(listener);
		return ()=>{
			instance.removeUpdateListener(listener);
		};
	}, []);
	return instance;
}


import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import School from '../models/School';

import { P2b2cLogo, Dimens } from '../Resources';
import { Autocomplete, Box, Link, Typography, Divider } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';


const NavBar: React.FC = () => {
	const [loading, setLoading] = React.useState<boolean>(false);
	const [schoolOptions, setSchoolOptions] = React.useState<Array<object> | undefined>();
	const nav = useNavigate();

	React.useEffect(()=>{
		setLoading(true);
		setSchoolOptions(School.allActive().map((school: School, i: number)=>({ label: school.shortName, code: school.code })));
		setLoading(false);
	}, []);

	return <Box width='100%' maxWidth={Dimens.maxWidth}>
		<Box display='flex' flexDirection='row' flexWrap='wrap' alignItems='center' mx='auto' mt={3} px={3} gap={4}>
			<Link underline='none' onClick={()=>nav('/')} width={{ xs: '100%', sm: 'auto' }}><P2b2cLogo /></Link>
			{/*<Link underline='none' onClick={()=>nav('/')}><Typography textColor='black'>Properties</Typography></Link>*/}
			<Link underline='none' onClick={()=>nav('/p1-school-ranking')}>
				<Typography fontSize='sm' textColor='black'>P1 School Ranking</Typography>
			</Link>
			<Divider orientation='vertical' sx={{ bgcolor: '#ccc', height: '1rem', alignSelf: 'center' }} />
			<Link underline='none' onClick={()=>nav('/p1-solution')}>
				<Typography fontSize='sm' textColor='black'>P1 Solution</Typography>
			</Link>
			<Box flex={1} />
			<Autocomplete size='sm' startDecorator={<FA icon={faMagnifyingGlass} />}
				placeholder='Schools' loading={loading} options={schoolOptions ?? []} onChange={(event, value: any)=>{
					if (!value?.code) return;
					nav(`/school/${value.code}`);
				}} />
		</Box>
	</Box>;
}

export default NavBar;



import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSearchParams } from 'react-router-dom';

import Rank from '../models/Rank';
import School from '../models/School';
import { useSettings } from '../models/Settings';
import { Colors, Styles, Dimens } from '../Resources';

import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import SchoolCard from '../components/SchoolCard';
import SolutionToolbar, { SolutionFilter } from '../components/SolutionToolbar';
import { Box, Typography, Divider, Card, Chip, CircularProgress } from '@mui/joy';
import { Modal, ModalDialog, DialogTitle, ModalClose } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faChair, faChild, faAsterisk, faThumbsUp } from '@fortawesome/free-solid-svg-icons';


const genderLabel = [
	{ value: 'all', label: '' },
	{ value: 'mixed', label: 'mixed' },
	{ value: 'girl', label: 'girls\'' },
	{ value: 'boy', label: 'boys\'' },
];

const citizenShortLabel = [
	{ value: -1, label: 'all' },
	{ value: 2, label: 'SC' },
	{ value: 1, label: 'PR' },
];

const citizenLabel = [
	{ value: -1, label: 'all residents' },
	{ value: 2, label: 'Singapore citizen' },
	{ value: 1, label: 'permanent resident' },
];

const distanceLabel = [
	{ value: -1, label: '' },
	{ value: 3, label: '< 1km' },
	{ value: 2, label: '1-2km' },
	{ value: 1, label: '> 2km' },
];

const SolutionPage: React.FC = () => {
	const isBigScreen = useMediaQuery({ query: '(min-width: 1024px)' });
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
	const settings = useSettings();
	const [searchParams, setSearchParams] = useSearchParams();
	const [loading, setLoading] = React.useState<boolean>(false);
	const [filter, setFilter] = React.useState<SolutionFilter | undefined>();
	const [schools, setSchools] = React.useState<any | undefined>();
	const [ranks2B, setRanks2B] = React.useState<Array<Rank> | undefined>();
	const [ranks2C, setRanks2C] = React.useState<Array<Rank> | undefined>();
	const [ranks2CS, setRanks2CS] = React.useState<Array<Rank> | undefined>();
	const [solution2B, setSolution2B] = React.useState<Array<Rank> | undefined>();
	const [solution2C, setSolution2C] = React.useState<Array<Rank> | undefined>();
	const [solution2CS, setSolution2CS] = React.useState<Array<Rank> | undefined>();
	const [openSchool, setOpenSchool] = React.useState<School | undefined>();

	React.useEffect(()=>{
		if (!filter?.postal || !filter?.blk) return;
		setLoading(true);
		School.allOnPostal(filter.postal, filter.blk, (obj: any)=>setSchools(obj));
	}, [filter?.postal, filter?.blk]);

	React.useEffect(()=>{
		if (!filter?.citizen || !filter?.gender) return;
		if (!schools) return;
		setLoading(true);

		let ds = settings.solutionDataSet;
		let d1Ranks2B = Rank.create(ds.fromYear, ds.toYear, '2B', filter.citizen, 3);
		let d1Ranks2C = Rank.create(ds.fromYear, ds.toYear, '2C', filter.citizen, 3);
		let d1Ranks2CS = Rank.create(ds.fromYear, ds.toYear, '2CS', filter.citizen, 3);
		let d2Ranks2B = Rank.create(ds.fromYear, ds.toYear, '2B', filter.citizen, 2);
		let d2Ranks2C = Rank.create(ds.fromYear, ds.toYear, '2C', filter.citizen, 2);
		let d2Ranks2CS = Rank.create(ds.fromYear, ds.toYear, '2CS', filter.citizen, 2);

		let d1s = schools.distance1;
		let d2s = schools.distance2;

		let ranks2B = d1Ranks2B.filter((r: Rank)=>d1s.find((sch: School)=>r.school.code === sch.code))
		.concat(d2Ranks2B.filter((r: Rank)=>d2s.find((sch: School)=>r.school.code === sch.code)))
		.filter((r: Rank)=>filter.gender === 'all' || filter.gender === r.school.gender.toLowerCase())
		.sort((a: Rank, b: Rank)=>a.no! - b.no!);
		setRanks2B(ranks2B);

		let ranks2C = d1Ranks2C.filter((r: Rank)=>d1s.find((sch: School)=>r.school.code === sch.code))
		.concat(d2Ranks2C.filter((r: Rank)=>d2s.find((sch: School)=>r.school.code === sch.code)))
		.filter((r: Rank)=>filter.gender === 'all' || filter.gender === r.school.gender.toLowerCase())
		.sort((a: Rank, b: Rank)=>a.no! - b.no!);
		setRanks2C(ranks2C);

		let ranks2CS = d1Ranks2CS.filter((r: Rank)=>d1s.find((sch: School)=>r.school.code === sch.code))
		.concat(d2Ranks2CS.filter((r: Rank)=>d2s.find((sch: School)=>r.school.code === sch.code)))
		.filter((r: Rank)=>filter.gender === 'all' || filter.gender === r.school.gender.toLowerCase())
		.sort((a: Rank, b: Rank)=>a.no! - b.no!);
		setRanks2CS(ranks2CS);
	}, [settings.solutionDataSet, filter?.citizen, filter?.gender, schools]);

	React.useEffect(()=>{
		if (!ranks2B || !ranks2C) return;

		if (!settings.hideBadSolution) {
			setSolution2B(ranks2B);
			setLoading(false);
			return;
		}
		let solution = ranks2B.filter((r: Rank)=>{
			let hasVacancy = r.trend.sum.vacancy && r.trend.sum.vacancy > 0;
			let hasBetter = r.trend.sum.rate === 1 && ranks2C!.find((better: Rank)=>r.school.code === better.school.code)?.trend.sum.rate === 1;
			return hasVacancy && !hasBetter;
		});
		setSolution2B(solution);
		setLoading(false);
	}, [settings.hideBadSolution, ranks2B, ranks2C]);

	React.useEffect(()=>{
		if (!ranks2C || !ranks2CS) return;
		if (!settings.hideBadSolution) {
			setSolution2C(ranks2C);
			setLoading(false);
			return;
		}
		let solutionHasVacancy = ranks2C.filter((r: Rank)=>{
			return r.trend.sum.vacancy && r.trend.sum.vacancy > 0;
		});
		let solutionNeedBallot = solutionHasVacancy.filter((r: Rank)=>{
			return r.trend.sum.rate < 1;
		});
		if (solutionNeedBallot.length === 0) {
			setSolution2C(solutionHasVacancy);
			setLoading(false);
			return;
		}
		let solution = solutionHasVacancy.filter((r: Rank)=>{
			let hasBetter = r.trend.sum.rate === 1 && ranks2CS!.find((better: Rank)=>r.school.code === better.school.code)?.trend.sum.rate === 1;
			return !hasBetter;
		});
		setSolution2C(solution);
		setLoading(false);
	}, [settings.hideBadSolution, ranks2C, ranks2CS]);

	React.useEffect(()=>{
		if (!ranks2CS || !solution2C) return;
		if (!settings.hideBadSolution) {
			setSolution2CS(ranks2CS);
			setLoading(false);
			return;
		}
		let solution = ranks2CS.filter((r: Rank)=>{
			let hasVacancy = r.trend.sum.vacancy && r.trend.sum.vacancy > 0;
			let hasBetter = r.trend.sum.rate <= (solution2C!.find((better: Rank)=>r.school.code === better.school.code)?.trend.sum.rate ?? 0);
			return hasVacancy && !hasBetter;
		});
		setSolution2CS(solution);
		setLoading(false);
	}, [settings.hideBadSolution, ranks2CS, solution2C]);

	const gender = React.useMemo(()=>genderLabel.find(e=>e.value === filter?.gender)?.label, [filter]);
	const citizenship = React.useMemo(()=>citizenLabel.find(e=>e.value === filter?.citizen)?.label, [filter]);
	const dataSetRange = React.useMemo(()=>{
		let from = settings.solutionDataSet.fromYear;
		let to = settings.solutionDataSet.toYear;
		return from === to ? `${from}` : `${from}-${to}`;
	}, [settings.solutionDataSet]);

	return <Box display='flex' flexDirection='column' flex={1} alignItems='center'>
		<NavBar />
		<Box display='flex' flexDirection='column' flex={1} mx={3} maxWidth={Dimens.maxFocusWidth}>
			<SolutionToolbar loading={loading} onFiltered={(filter: SolutionFilter)=>setFilter(filter)} sx={{ mt: 4, mx: 'auto', maxWidth: 'max-content' }} />
			{loading ? <CircularProgress sx={{ my: 4, alignSelf: 'center' }} /> : (filter?.postal && filter?.blk) ? <>
				<Divider><Typography fontSize='lg' fontWeight='bold' textAlign='center' whiteSpace={{ xs: 'wrap', sm: 'noWrap' }} my={4}>
					Solution for {citizenship} residing at<br />
					{searchParams.get('addr') ?? `# ${filter?.blk} - ${filter?.postal}`}
				</Typography></Divider>
				<SolutionGroup phaseCode='2B'
					noSchool={<>
						No {gender} schools have vacancies for {citizenship} in phase 2B.<br />
						Or phase 2C is better for application (no volunteering needed).
					</>}
					tips={<>
						<FA icon={faAsterisk} /> The ranking is based on each school's admission rate (vacancies / applicants, from low to high) in phase 2B ({dataSetRange}).
						{settings.hideBadSolution && <>
							<br /><br /><FA icon={faThumbsUp} /> A school with 100% admission rate in phase 2C will skip phase 2B (no volunteering needed).
						</>}
					</>}>
					{solution2B && solution2B.length > 0 && solution2B.map((r: Rank)=><SolutionItem key={r.no} rank={r} onClick={(school: School)=>setOpenSchool(school)} />)}
				</SolutionGroup>
				<Divider sx={{ my: 4, bgcolor: Styles.divider }} />
				<SolutionGroup phaseCode='2C'
					noSchool={<>
						No {gender} schools have vacancies for {citizenship} in phase 2C.<br />
						Or phase 2CS is better for application.
					</>}
					tips={<>
						<FA icon={faAsterisk} /> The ranking is based on each school's admission rate (vacancies / applicants, from low to high) in phase 2C ({dataSetRange}).
						{settings.hideBadSolution && <>
							<br /><br /><FA icon={faThumbsUp} /> When balloting is conducted in phase 2C, a school with 100% admission rate in phase 2CS will skip phase 2C.
						</>}
					</>}>
					{solution2C && solution2C.length > 0 && solution2C.map((r: Rank)=><SolutionItem key={r.no} rank={r} onClick={(school: School)=>setOpenSchool(school)} />)}
				</SolutionGroup>
				<Divider sx={{ my: 4, bgcolor: Styles.divider }} />
				<SolutionGroup phaseCode='2CS'
					noSchool={<>
						No {gender} schools have vacancies for {citizenship} in phase 2CS.<br />
						Or phase 2C is better for application.
					</>}
					tips={<>
						<FA icon={faAsterisk} /> The ranking is based on each school's admission rate (vacancies / applicants, from low to high) in phase 2CS ({dataSetRange}).
					</>}>
					{solution2CS && solution2CS.length > 0 && solution2CS.map((r: Rank)=><SolutionItem key={r.no} rank={r} onClick={(school: School)=>setOpenSchool(school)} />)}
				</SolutionGroup>
			</> : <Typography fontSize='sm' textColor='#999' mt={4} mx='auto' startDecorator={<FA icon={faMagnifyingGlass} />}>Enter the postal code or address to get started.</Typography>}
		</Box>
		<Footer sx={{ mt: 6 }} />
		<Modal open={openSchool !== undefined} onClose={()=>setOpenSchool(undefined)} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<ModalDialog sx={{
				...Dimens.maxDialogWidth,
				minWidth: isTabletOrMobile && !isBigScreen ? '100%' : undefined,
				minHeight: isTabletOrMobile && !isBigScreen ? '100%' : undefined,
				borderRadius: isTabletOrMobile && !isBigScreen ? '0px' : undefined,
				paddingTop: isTabletOrMobile && !isBigScreen ? '36px' : undefined,
			}}>
				<ModalClose />
				<SchoolCard school={openSchool!} />
			</ModalDialog>
		</Modal>
	</Box>;
}

interface SolutionGroupProps {
	phaseCode: string;
	children: React.ReactNode;
	noSchool: React.ReactNode;
	tips: React.ReactNode;
}

const SolutionGroup: React.FC<SolutionGroupProps> = ({ phaseCode, children, noSchool, tips }) => {
	return <Box display='flex' flexDirection={{ xs: 'column', sm: 'row' }} alignItems='start' gap={{ xs: 2, sm: 4 }}>
		<Box my={{ xs: 0, sm: 2 }} ml={{ xs: 1, sm: 0 }} width={64}><img alt='' src={`images/${phaseCode}.svg`} height={32} /></Box>
		{children && <Box display='flex' flexDirection='column' justifyContent='center' gap={2} width='100%' maxWidth={560}>{children}</Box>}
		{!children && <Typography fontSize='sm' mx={{ xs: 1, sm: 0 }} my='auto' width={{ xs: 'auto', sm: '100%' }} maxWidth={560}>{noSchool}</Typography>}
		{children && <Typography fontSize='xs' textColor='#999' flex={1} mx={{ xs: 1, sm: 0 }} my={{ xs: 0, sm: 2 }}>{tips}</Typography>}
	</Box>
}

interface SolutionItemProps {
	rank: Rank;
	onClick: (school: School)=>void;
}

const SolutionItem: React.FC<SolutionItemProps> = ({ rank, onClick }) => {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
	let eligible = rank.trend.sum.vacancy! > 0;
	let rate = rank.trend.sum.rate;
	let percent = rate === 1 ? '100' : Number(rate * 100).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
	return <Card onClick={()=>onClick(rank.school)} sx={{ cursor: 'pointer' }}>
		<Box display='flex' flexDirection='row'>
			<Box display='flex' flexDirection='column' flex={1} gap={0.5}>
				<Box display='flex' flexDirection='row'>
					<Typography fontSize='lg' fontWeight='bold' width={40} textColor={ eligible ? 'black' : Colors.muiDarkGray }>{rank.no}</Typography>
					<Typography fontSize='lg' fontWeight='bold' textColor={ eligible ? 'black' : Colors.muiDarkGray }>{isTabletOrMobile ? rank.school.shortName : rank.school.name}</Typography>
				</Box>
				<Box display='flex' flexDirection='row' gap={1} ml={5}>
					{[
						distanceLabel.find(e=>e.value === rank.trend.sum.distance)?.label ?? undefined,
						rank.school.gender !== 'Mixed' ? rank.school.gender : undefined,
						rank.school.gep ? 'GEP' : undefined,
						rank.school.sap ? 'SAP' : undefined,	
					].map(e=>e && <Chip key={e} variant='soft' size='sm' sx={{ color: '#999', opacity: eligible ? 1 : 0.5 }}>{e}</Chip>)}
				</Box>
			</Box>
			<Box display='flex' flexDirection='column' textAlign='right' ml={4} gap={0.5} alignSelf='center'>
				{eligible
					? <Typography fontSize='lg' fontWeight='bold'>{percent}<Typography fontSize='xs' fontWeight='bold'>%</Typography></Typography>
					: <Typography fontSize='xs' textColor='#999'>No Vac. for {citizenShortLabel.find(e=>e.value === rank.citizen)?.label}</Typography>}
				{eligible && <Typography fontSize='xs' textColor='#999'><FA icon={faChair} color='#bbb' /> {rank.trend.sum.vacancy} <FA icon={faChild} color='#bbb' /> {rank.trend.sum.applied}</Typography>}
			</Box>
		</Box>
	</Card>;
}

export default SolutionPage;


import * as React from 'react';

import School from '../models/School';
import { Registration, supportYears } from '../models/Registration';

import { Sheet, Grid } from '@mui/joy';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faChair as cr, faChild as cd } from '@fortawesome/free-solid-svg-icons';

const theme = extendTheme({
	components: {
		JoyGrid: {
			styleOverrides: {
				root: {
					fontSize: '12px',
					textAlign: 'center',
					borderBottom: '1px solid #ddd',
					borderRight: '1px solid #ddd',
					padding: '4px 0px',
				},
			},
		},
	},
});

const PhaseFlatGrid: React.FC<{ school: School, phaseCode: string, yearOutdated?: number }> = ({ school, phaseCode, yearOutdated }) => {
	const [rows, setRows] = React.useState<Array<React.ReactNode>>();
	React.useEffect(()=>{
		setRows(Array.from(supportYears).reverse().map((year: number)=>{
			if (yearOutdated && year <= yearOutdated) return [];
			let reg = Registration.allForYear(year).find((reg: Registration)=>reg.schoolCode === school.code);
			if (!reg) return [];
			let phase = reg.phase(phaseCode);
			if (!phase) return [];
			let yearGrid = <Grid key={`${year}0`} xs={1} borderLeft='1px solid #ddd'>{year}</Grid>;
			let total = phase.total;
			let ballot = phase.ballot;
			if (ballot) {
				let g3 = ((ballot.distance ?? 1) + ((ballot.citizen ?? 2) > 1 ? 2 : -1)) * 2;
				let g2 = ballot.distance ? 2 : 6;
				let g1 = ballot.distance ? 10 - g3 : ((ballot.citizen ?? 2) > 1 ? 0 : 6);
				return [
					yearGrid,
					g1 > 0 && (ballot.vacancy ? <Grid key={`${year}1`} xs={g1}><FA icon={cr} /> {total.vacancy - ballot.vacancy} &nbsp;<FA icon={cd} /> {total.vacancy - ballot.vacancy}</Grid> : <Grid key={`${year}1`} xs={g1}>100%</Grid>),
					<Grid key={`${year}2`} xs={g2}><FA icon={cr} /> {ballot.vacancy ?? (g1 > 0 ? <span style={{ color: '#aaa'}}>⁇</span> : total.vacancy)} &nbsp;<FA icon={cd} /> {ballot.applied ?? <span style={{ color: '#aaa'}}>⁇</span>}</Grid>,
					g3 > 0 && (ballot.vacancy && ballot.applied ? <Grid key={`${year}3`} xs={g3}><FA icon={cr} /> 0 &nbsp;<FA icon={cd} /> {total.applied - (total.vacancy - ballot.vacancy) - ballot.applied}</Grid> : <Grid key={`${year}3`} xs={g3}>0%</Grid>),
					<Grid key={`${year}4`} xs={2}><FA icon={cr} /> {total.vacancy} &nbsp;<FA icon={cd} /> {total.applied}</Grid>,
				];
			} else if (total.citizen === undefined) {
				if (total.distance === undefined) {
					return [
						yearGrid,
						<Grid key={`${year}1`} xs={12}><FA icon={cr} /> {total.vacancy} &nbsp;<FA icon={cd} /> {total.applied}</Grid>,
						<Grid key={`${year}2`} xs={2}><FA icon={cr} /> {total.vacancy} &nbsp;<FA icon={cd} /> {total.applied}</Grid>,
					];	
				}
				let offset = ((total.distance ?? 1) - 1) * 2;
				return [
					yearGrid,
					<Grid key={`${year}1`} xs={6 - offset}>100%</Grid>,
					<Grid key={`${year}2`} xs={offset}>0%</Grid>,
					<Grid key={`${year}3`} xs={6 - offset}>100%</Grid>,
					<Grid key={`${year}4`} xs={offset}>0%</Grid>,
					<Grid key={`${year}5`} xs={2}><FA icon={cr} /> {total.vacancy} &nbsp;<FA icon={cd} /> {total.applied}</Grid>,
				];
			} else {
				let g2 = ((total.distance ?? 1) + (total.citizen > 1 ? 2 : -1)) * 2;
				let g1 = 12 - g2;
				return [
					yearGrid,
					g1 > 0 && <Grid key={`${year}1`} xs={g1}><FA icon={cr} /> {total.vacancy} &nbsp;<FA icon={cd} /> {total.vacancy}</Grid>,
					g2 > 0 && <Grid key={`${year}2`} xs={g2}><FA icon={cr} /> 0 &nbsp;<FA icon={cd} /> {total.applied - total.vacancy}</Grid>,
					<Grid key={`${year}3`} xs={2}><FA icon={cr} /> {total.vacancy} &nbsp;<FA icon={cd} /> {total.applied}</Grid>,
				];
			}
		}).flat());
	}, [school, phaseCode, yearOutdated]);

	return <Sheet sx={{ overflowX: 'auto' }}>
		<CssVarsProvider theme={theme}>
			<Grid container columns={15} p={0} sx={{
				whiteSpace: 'noWrap', minWidth: 680,
				borderTop: '1px solid #ddd', borderLeft: 'none', borderRight: 'none', borderBottom: 'none',
				'.fa-chair, .fa-child': { color: '#bbb' } }}>
				<Grid xs={1} bgcolor='#f5f5f5' borderLeft='1px solid #ddd'>Year</Grid>
				<Grid xs={2} bgcolor='#f5f5f5'>{'SC < 1km'}</Grid>
				<Grid xs={2} bgcolor='#f5f5f5'>{'SC 1-2km'}</Grid>
				<Grid xs={2} bgcolor='#f5f5f5'>{'SC > 2km'}</Grid>
				<Grid xs={2} bgcolor='#f5f5f5'>{'PR < 1km'}</Grid>
				<Grid xs={2} bgcolor='#f5f5f5'>{'PR 1-2km'}</Grid>
				<Grid xs={2} bgcolor='#f5f5f5'>{'PR > 2km'}</Grid>
				<Grid xs={2} bgcolor='#f5f5f5'>Overall</Grid>
				{rows}
			</Grid>
		</CssVarsProvider>
	</Sheet>;
}

export default PhaseFlatGrid;


import * as React from 'react';

import { PhaseTrend } from '../models/Registration';


interface PhaseTrendChartProps {
	trend: PhaseTrend;
	width: number;
	height: number;
}

const PhaseTrendChart: React.FC<PhaseTrendChartProps> = ({ trend, width, height }) => {
	const ref = React.createRef<HTMLCanvasElement>();

	React.useEffect(()=>{
		let canvas = ref.current;
		if (!canvas) return;
		let ctx = canvas.getContext('2d')!;
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		if (trend.seq.length === 1) return;
		if (trend.sum.applied === 0) return;
		let padding = 5;
		let width = canvas.width - padding * 2;
		let height = canvas.height - padding * 2;
		let points = new Array<any>();
		let minY = 10000;
		let maxY = 0;
		for (let i = 0; i < trend.seq.length; i++) {
			let x = width / (trend.seq.length - 1) * i + padding;
			let y = height - trend.seq[i].rate * height + padding;
			minY = Math.min(minY, y);
			maxY = Math.max(maxY, y);
			points.push({ x: x, y: y });
		}
		let offset = canvas.height * 0.5 - ((maxY - minY) * 0.5 + minY);
		ctx.beginPath();
		points.forEach((p: any, i: number)=>{
			let enroll = trend.seq[i];
			if (enroll.vacancy !== undefined || enroll.applied !== undefined) {
				ctx.moveTo(p.x, p.y + offset);
				ctx.arc(p.x, p.y + offset, 3, 0, 2 * Math.PI);
			}
		});
		ctx.fill();
		ctx.beginPath();
		let origin = points.shift();
		ctx.moveTo(origin.x, origin.y + offset);
		points.forEach((p: any, i: number)=>{
			let enroll = trend.seq[i];
			if (enroll.vacancy === undefined && enroll.applied === undefined) {
				ctx.moveTo(p.x, p.y + offset);
			} else {
				ctx.lineTo(p.x, p.y + offset);
			}
		});
		ctx.stroke();
	}, [ref, trend]);

	return <canvas ref={ref} width={width} height={height} />;

}

export default PhaseTrendChart;


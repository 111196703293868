import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import HomePage from './pages/HomePage'
import RankPage from './pages/RankPage';
import SolutionPage from './pages/SolutionPage';
import SchoolPage from './pages/SchoolPage';
import '@fontsource/inter';

const App: React.FC = () => {
    return <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Router>
            <Routes>
                <Route index element={<HomePage />} />
                <Route path='p1-school-ranking' element={<RankPage />} />
                <Route path='p1-solution' element={<SolutionPage />} />
                <Route path='school/:code' element={<SchoolPage />} />
            </Routes>
        </Router>
    </div>;
}

export default App;

import School from './School';
import { Registration, PhaseTrend, Phase, Enroll } from './Registration';


export default class Rank {

	no: number | undefined;
	school: School;
	citizen: number;
	distance: number;
	trend: PhaseTrend;
	
	private _score: Enroll;

	constructor(school: School, phases: Array<Phase>, citizen: number, distance: number) {
		this.school = school;
		this.citizen = citizen;
		this.distance = distance;
		this.trend = new PhaseTrend(phases, citizen, distance);
		this._score = new Enroll({
			vacancy: phases.reduce((sum, e) => sum + (e.total.vacancy ?? 0), 0),
			applied: phases.reduce((sum, e) => sum + (e.total.applied ?? 0), 0),
			citizen: citizen,
			distance: distance,
		});
	}

	compare(other: Rank) {
		const aVacancy = this._score.vacancy ?? 0;
		const aApplied = this._score.applied ?? 0;
		const bVacancy = other._score.vacancy ?? 0;
		const bApplied = other._score.applied ?? 0;
		const aRate = aApplied === 0 ? 10000 : (aVacancy / aApplied);
		const bRate = bApplied === 0 ? 10000 : (bVacancy / bApplied);
		if (aRate < bRate) return -1;
		if (aRate > bRate) return 1;
		if (aApplied > bApplied) return -1;
		if (aApplied < bApplied) return 1;
		if (this.school.name < other.school.name) return -1;
		if (this.school.name > other.school.name) return 1;
		return 0;
	}

	static create(fromYear: number, toYear: number, phaseCode: string, citizen: number, distance: number) {
		let years = new Array<Array<Registration>>();
		for(let i = Math.min(fromYear, toYear); i <= Math.max(fromYear, toYear); i++) {
			years.push(Registration.allForYear(i));
		}
		// years.push(Registration.test());
		years.reverse();
		let ranks = years[0].map((reg: Registration) => {
			let school = School.all().find((e: School) => e.code === reg.schoolCode)!;
			let phases = [reg.phase(phaseCode)!];
			for (let i = 1; i < years.length; i++) {
				let regs = years[i];
				let reg = regs.find((reg: Registration) => school.code === reg.schoolCode);
				let phase = reg?.phase(phaseCode) ?? new Phase({ code: phaseCode, total: {} });
				phases.push(phase);
			}
			phases.reverse();
			return new Rank(school, phases, citizen, distance);
		});
		ranks.sort((a: Rank, b: Rank)=>a.compare(b));
		for (let i = 0; i < ranks.length; i++) {
			ranks[i].no = i + 1;
		}
		return ranks;
	}

}